/* src/DeleteAccount.css */
.delete-account-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
}

.logo-container {
  margin-top: 20px;
}

.delete-account-content {
  margin-top: 20px;
  text-align: left;
}

.delete-account-section {
  margin-top: 20px;
}
