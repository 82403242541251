/* Loader.css */
.loader-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
  }
  
  .loader {
    position: relative;
    width: 80px;
    height: 80px;
  }
  
  .circle {
    position: absolute;
    border: 4px solid transparent;
    border-top-color: #007bff; /* Adjust color as needed */
    border-radius: 50%;
    animation: spin 1s linear infinite;
  }
  
  .circle-1 {
    width: 80px;
    height: 80px;
  }
  
  .circle-2 {
    width: 60px;
    height: 60px;
    animation-delay: -0.5s;
  }
  
  .circle-3 {
    width: 40px;
    height: 40px;
    animation-delay: -1s;
  }
  
  .circle-4 {
    width: 20px;
    height: 20px;
    animation-delay: -1.5s;
  }
  
  @keyframes spin {
    100% {
      transform: rotate(360deg);
    }
  }
  