/* About.css */
.about-container {
    padding: 50px;
    text-align: center;
}

.logo-container {
    margin-bottom: 20px;
}

.logo {
    width: 150px;
    height: auto;
    border-radius: 50%;
    margin-top: -30px; 
}

h1 {
    margin-bottom: 20px;
    font-size: 32px;
}

.description {
    margin-bottom: 20px;
    font-size: 18px;
    line-height: 1.6;
}

.feature-list {
    margin-bottom: 20px;
    text-align: center; /* Center the list */
    list-style-type: disc; /* Add dots for each list item */
    padding-left: 0; /* Remove default padding */
}

.feature-list li {
    margin-bottom: 10px; /* Add space between list items */
}

.main-btn {
    display: inline-block;
    padding: 10px 20px;
    background-color: #007bff;
    color: #fff;
    text-decoration: none;
    border-radius: 5px;
    transition: background-color 0.3s ease;
}

.main-btn:hover {
    background-color: #0056b3;
}

.btn-hover {
    transition: transform 0.3s ease;
}

.btn-hover:hover {
    transform: translateY(-3px);
}
.about-container .about-content ul {
    text-align: center;
  }
  
  /* Style the list items */
  .about-container .about-content ul li {
    list-style: none; /* Remove default bullets */
    position: relative;
    padding-left: 20px; /* Add some padding for the dot */
  }
  
  /* Create the dots */
  .about-container .about-content ul li::before {
    content: "\2022"; /* Unicode for bullet */
    position: absolute;
    left: 0;
  }