.terms-of-service-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
}

.terms-of-service-title {
    font-size: 32px;
    color: #333;
    margin-bottom: 20px;
}

.terms-of-service-content {
    font-size: 18px;
    line-height: 1.6;
    color: #666;
}

.terms-of-service-content p {
    margin-bottom: 15px;
}

.terms-of-service-content a {
    color: #007bff;
    text-decoration: none;
}

.terms-of-service-content a:hover {
    text-decoration: underline;
}
